//
//
//
//
//
//
//
//
//
//
//
//

import { defaultBlockProps } from "~/helpers/data";

export default {
    name: "BowContentMediaDx",

    props: {
        ...defaultBlockProps,

        mediaToggle: {
            default: false,
            type: [Boolean, Array],
        },
    },

    data: () => ({}),
};
