import { render, staticRenderFns } from "./BowContentMedia.vue?vue&type=template&id=fc365ffa&"
import script from "./BowContentMedia.vue?vue&type=script&lang=js&"
export * from "./BowContentMedia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowHeading: require('/workspace/components/atoms/BowHeading/BowHeading.vue').default,BowText: require('/workspace/components/atoms/BowText/BowText.vue').default,BowCallToAction: require('/workspace/components/molecules/BowCallToAction/BowCallToAction.vue').default,BowAnimation: require('/workspace/components/base/BowAnimation/BowAnimation.vue').default,BowMedia: require('/workspace/components/molecules/BowMedia/BowMedia.vue').default})
