//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { checkBreakpoint, defaultBlockProps, devices } from "~/helpers";

export default {
    name: "BowMediaContent",

    props: {
        ...defaultBlockProps,

        mediaToggle: {
            default: false,
            type: [Boolean, Array],
        },

        type: {
            default: "sx",
            type: String,
        },
    },

    data: () => ({
        checkBreakpointUpLg: false,
        devices: {
            isDesktop: false,
        },
    }),

    mounted() {
        this.checkBreakpointUpLg = checkBreakpoint.up("lg");
        this.devices.isDesktop = devices.isDesktop;
    },
};
