var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.htmlTag ? _vm.htmlTag : 'div',{tag:"component",class:[
        'o-content-media',
        _vm.type === 'dx' ? 'o-content-media-dx' : 'o-content-media-sx' ]},[_c('div',{class:[
            'flex flex-col relative w-full',
            'lg:flex-row lg:gap-x-16 lg:items-center lg:justify-between',
            'xl:gap-x-32 2xl:gap-x-40' ]},[_c('div',{class:[
                'pt-16 px-side-spacer relative w-full lg:pt-0 xl:max-w-1/2',
                _vm.type === 'dx'
                    ? 'lg:pl-side-spacer lg:pr-0 xl:pl-side-spacer-desktop'
                    : 'lg:pr-side-spacer lg:pl-0 xl:pr-side-spacer-desktop',
                _vm.type === 'sx' ? 'order-2' : 'order-2 lg:order-1' ]},[_c('BowAnimation',{staticClass:"w-full",attrs:{"call":"fade","offset":"25%, 15%","speed":1}},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"relative w-full"},[_c('div',{staticClass:"w-full"},[(_vm.title && _vm.title.length > 0)?_c('BowHeading',{staticClass:"text-primary",attrs:{"customSize":"text-5xl 2xl:text-7xl"}},_vm._l((_vm.title),function(single,idx){return _c('span',{key:idx,class:[
                                        'sm:block',
                                        single.style_heading &&
                                        single.style_heading === 'font-bold'
                                            ? 'font-bold'
                                            : '' ]},[_vm._v("\n                                    "+_vm._s(single.value_heading
                                            ? single.value_heading
                                            : "")+"\n                                ")])}),0):_vm._e(),_vm._v(" "),_c('client-only',[(_vm.text && _vm.text !== '')?_c('BowText',{staticClass:"mt-16",attrs:{"align":"text-left"},domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()],1),_vm._v(" "),_c('BowCallToAction',{staticClass:"mt-16 text-primary",attrs:{"componentType":"circle","cta":_vm.cta,"theme":"secondary"}})],1)])])])],1),_vm._v(" "),_c('div',{class:[
                'relative w-full lg:max-w-1/2',
                _vm.devices.isDesktop && false ? 'lg:overflow-hidden' : '',
                _vm.type === 'sx' ? 'order-1' : 'order-1 lg:order-2' ]},[(
                    _vm.media[0] &&
                    ((_vm.mediaToggle !== false &&
                        _vm.mediaToggle.length > 0 &&
                        _vm.checkBreakpointUpLg) ||
                        _vm.mediaToggle === false)
                )?_c('BowAnimation',{key:_vm.type === 'sx' ? 'image-left' : 'image-right',staticClass:"w-full",attrs:{"speed":1,"speedMobile":0.5}},[_c('div',{staticClass:"w-full"},[(
                            _vm.media[0].type === 'image' &&
                            _vm.media[0].image &&
                            _vm.media[0].image.url !== ''
                        )?_c('BowMedia',{class:[
                            'mx-auto relative transform z-0',
                            _vm.type === 'sx'
                                ? '-translate-x-[5%] lg:-translate-x-[15%]'
                                : 'translate-x-[15%] lg:translate-x-[35%]',
                            _vm.mediaToggle !== false && _vm.mediaToggle.length > 0
                                ? 'hidden lg:block'
                                : '' ],attrs:{"classWrapper":[
                            'h-auto pt-[120%] relative w-[70%] lg:pt-media-padding lg:w-[80%]' ],"image":_vm.media[0].image,"radius":true,"ring":false}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('div',{class:[
                    _vm.mediaToggle !== false && _vm.mediaToggle.length > 0
                        ? [
                              'mx-auto relative w-full',
                              'md:w-2/3 lg:absolute lg:right-0 lg:pl-0',
                              'lg:top-1/2 lg:-translate-y-1/2 lg:z-0 xl:w-3/5' ]
                        : [
                              typeof _vm.media[0] !== 'undefined'
                                  ? [
                                        'absolute top-1/2 -translate-y-1/2',
                                        _vm.type === 'sx'
                                            ? 'right-side-spacer lg:right-0'
                                            : 'left-side-spacer lg:left-0',
                                        'w-1/2 lg:w-2/3' ]
                                  : 'px-side-spacer w-full lg:px-0 lg:w-2/3',
                              'z-0' ] ]},[_c('BowAnimation',{key:"image-right",staticClass:"w-full",attrs:{"speed":2,"speedMobile":1.5}},[_c('div',{staticClass:"w-full"},[_c('BowMedia',{attrs:{"classWrapper":"h-auto pt-[calc((16_/_10)_*_100%)] relative w-full","classImage":"h-full object-cover w-full","classPictureExtra":_vm.mediaToggle
                                    ? ''
                                    : 'scale-x-card-x scale-y-card-y',"imagesToggle":_vm.mediaToggle,"image":_vm.mediaToggle === false &&
                                _vm.media &&
                                _vm.media[1] &&
                                _vm.media[1].type === 'image'
                                    ? _vm.media[1].image
                                    : null,"ringClassExtra":[
                                _vm.mediaToggle
                                    ? 'scale-x-card-x scale-y-card-y z-10 2xl:scale-[0.85]'
                                    : '' ]}})],1)])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }