//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { checkBreakpoint } from "~/helpers";

export default {
    name: "BowAnimation",
    props: {
        htmlTag: {
            default: null,
            type: String,
        },
        call: {
            default: null,
            type: String,
        },
        delay: {
            default: 0,
            type: Number,
        },
        direction: {
            default: "vertical",
            type: String,
        },
        // factor: {
        //   default: 0,
        //   type: [ Number, Array ]
        // },
        offset: {
            default: null,
            type: String,
        },
        // onlyDesktop: {
        //   default: false,
        //   type: Boolean
        // },
        // onlyMobile: {
        //   default: false,
        //   type: Boolean
        // },
        position: {
            default: null,
            type: String,
            validator: (value) =>
                ["top", "bottom", "left", "right"].includes(value),
        },
        speed: {
            default: null,
            type: Number,
        },
        speedMobile: {
            default: null,
            type: Number,
        },
        // translateFade: {
        //   default: true,
        //   type: Boolean
        // },
        // triggerPosition: {
        //   default: null,
        //   type: String,
        //   validation: (value) => [
        //     'top',
        //     'bottom',
        //     'elementTop',
        //     null
        //   ].includes(value)
        // },
        // type: {
        //   default: null,
        //   type: String
        // }
    },

    data: () => ({
        checkBreakpointUpXl: false,
    }),

    mounted() {
        this.checkBreakpointUpXl = checkBreakpoint.up("xl");
    },
};
